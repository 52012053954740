
import {defineComponent, onMounted, ref, watch} from "vue";
import DocumentService from "@/core/services/DocumentService";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import Translate from "@/views/translate/Translate.vue";

export default defineComponent({
  name: "DocumentPanel",
  components: {Translate, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref({type: 'LOADING'});
    const loadData = async (id) => {
      return DocumentService.getUrl(id)
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
